import { Api } from "./Api";

export const contasService = {
  getContas,
};
export const transacoesService = {
  getTransacoes,
  inserirTransacoes,
  getPeriodos,
  getBalanco
};

/* export const transacoesService = {
  getContas,
}; */

/* async function saveIngrediente(ingrediente) {
  return Api.post("/graphql", {
    query: `
    mutation saveIngrediente($_id: ObjectId, $nome: String!, $tipo: String!, $brix: Decimal,$sacarose: Decimal, $frutose: Decimal, $glicose: Decimal, $lactose: Decimal, $maltose: Decimal, $galactose: Decimal, $ph: Decimal, $alcool: Decimal, $solidosGordura: Decimal, $sngl: Decimal, $outrosSolidos: Decimal, $solidosAcucar: Decimal!, $solidosTotais: Decimal!, $pe: Decimal, $pac: Decimal, $fpdf: Decimal, $maximoRecomendado: Decimal) {
      saveIngrediente(_id: $_id, nome: $nome, tipo: $tipo, brix: $brix,sacarose: $sacarose, frutose: $frutose, glicose: $glicose, lactose: $lactose, maltose: $maltose, galactose: $galactose,  ph: $ph, alcool: $alcool, solidosGordura: $solidosGordura, sngl: $sngl, outrosSolidos: $outrosSolidos, solidosAcucar: $solidosAcucar, solidosTotais: $solidosTotais, pe: $pe, pac: $pac, fpdf: $fpdf, maximoRecomendado: $maximoRecomendado) {
        _id
        nome
        tipo
        brix
        sacarose
        frutose
        glicose
        lactose
        maltose
        galactose
        ph
        alcool
        solidosGordura
        sngl
        outrosSolidos
        solidosAcucar
        solidosTotais
        pe
        pac
        fpdf
        maximoRecomendado
      }
    }
    `,
    variables: ingrediente,
  })
    .then((response) => {
      return response.data.data.ingredientes;
    })
    .catch((err) => {
      throw err;
    });
} */
async function getPeriodos() {
  return Api.post("/graphql", {
    query: `query {
      periodos {
        periodo
        ano
        mes
      }
    }`,
  })
    .then((response) => {
      return response.data.data.periodos;
    })
    .catch((err) => {
      throw err;
    });
}

async function getContas(args = {}) {
  return Api.post("/graphql", {
    query: `query contas($_id: ObjectId, $nome: String) {
      contas(_id: $_id, nome: $nome) {
        _id
        nome
        natureza
        descricao
        criacao
        extincao
      }
    }`,
    variables: args,
  })
    .then((response) => {
      return response.data.data.contas;
    })
    .catch((err) => {
      throw err;
    });
}
async function getTransacoes(args = {}) {
  return Api.post("/graphql", {
    query: `query transacoes($_id:ObjectId $ano:Int, $mes:Int) {
      transacoes(_id:$_id, mes:$mes, ano:$ano) {
        _id
        _debit_account
        _credit_account
        date
        amount
        memo
      }
  }
    `,
    variables: args,
  })
    .then((response) => {
      return response.data.data.transacoes;
    })
    .catch((err) => {
      throw err;
    });
}
async function getBalanco(args = {}) {
  return Api.post("/graphql", {
    query: `query balanco($ano: Int, $mes: Int) {
      balanco(ano: $ano, mes: $mes) {
        _account
        credito
        debito
      }
    }
    `,
    variables: args,
  })
    .then((response) => {
      return response.data.data.balanco;
    })
    .catch((err) => {
      throw err;
    });
}
async function inserirTransacoes(transacao) {
  if (!transacao)
    throw Error("Deve haver ao menos uma transação");
  return Api.post("/graphql", {
    query: `mutation inserirTransacao($transacao: TransacaoInput) {
      inserirTransacao(transacao: $transacao) {
        _id
        amount
        memo
        _debit_account
        _credit_account
        date
      }
    }
    `,
    variables: { transacao },
  })
    .then((response) => {
      return response.data.data.inserirTransacao;
    })
    .catch((err) => {
      throw err;
    });
}
