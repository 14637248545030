const { receitasService } = require("./../services");
export const receita = {
  namespaced: true,
  state: {
    receitas: [],
    receita: {
      id: null,
      receita: "",
      parametros: {},
      ingredientes: [],
      complementos: []
    },
    balanceamento: {
    },
    receitaTotal: 0,
  },
  getters: {
    receitas: (state) => {
      return state.receitas.sort(({ receita: a }, { receita: b }) =>
        a < b ? -1 : a > b ? 1 : 0
      );
    },
    receita: (state) => {
      return state.receita;
    },
    receitaEmVolume: (state) => (fator) => {
    if (isNaN(fator)) fator = 1
      let ingredientes, complementos;

      ingredientes = state.receita.ingredientes.map((ingrediente) => ({
        ...ingrediente,
        quantidade: (ingrediente.quantidade * fator).toFixed(2),
        custo: ((ingrediente.quantidade * fator / 1000) * +ingrediente.preco).toFixed(2),
      }));
      complementos = state.receita.complementos.map((ingrediente) => ({
        ...ingrediente,
        quantidade: (ingrediente.quantidade * fator).toFixed(2),
        custo: ((ingrediente.quantidade * fator / 1000) * +ingrediente.preco).toFixed(2),
      }));
      return { ingredientes, complementos };
    },
    balanceamento: (state) => {
      return state.balanceamento
    }
  },

  actions: {
    async fetch_receitas({ commit }) {
      let receitas = await receitasService.getReceitas();

      commit("fetch_receitas", receitas);
    },
    async calculeBalanceamento({ commit, state }) {

      let { parametros, receita } = await receitasService.calculeReceita(state.receita)
      const { id, quantidade: quantidade } = receita[0]
      commit("updateBalanceamento", parametros);
      commit("update_ingrediente", { id, quantidade });
    },
    async fetch_receitaById({ commit, dispatch, rootGetters }, id) {
      let receita = await receitasService.getReceitaById(id);
      const ingredienteById = rootGetters["ingrediente/ingredienteById"];
      receita.ingredientes.forEach((ingrediente, i, array) => {
        array[i] = {
          ...ingredienteById(ingrediente.id),
          ...ingrediente,
        };
      });
      receita.complementos.forEach((ingrediente, i, array) => {
        array[i] = {
          ...ingredienteById(ingrediente.id),
          ...ingrediente,
        };
      });
      commit("load_receita_by_id", receita);
      dispatch("calculeBalanceamento")
    },
    add_ingrediente({ commit, dispatch, rootGetters }, { id, quantidade }) {
      if (quantidade) {
        let ingrediente = {
          ...rootGetters["ingrediente/ingredienteById"](id),
          quantidade: quantidade + "",
        };

        commit("add_ingrediente", ingrediente);
        dispatch("calculeBalanceamento")
      }
    },
    delete_ingrediente({ commit, dispatch }, ingrediente) {
      commit("delete_ingrediente", ingrediente);
      dispatch("calculeBalanceamento")
    },

    update_ingrediente({ commit, dispatch }, ingrediente) {
      commit("update_ingrediente", ingrediente);
      dispatch("calculeBalanceamento")
    },
    replace_ingrediente({ commit, dispatch, rootGetters }, { id: old_id, quantidade, replace_id }) {
      commit("replace_ingrediente", {
        ...rootGetters["ingrediente/ingredienteById"](replace_id),
        quantidade, old_id
      });
      dispatch("calculeBalanceamento")
    },
    replace_complemento({ commit, dispatch, rootGetters }, { id: old_id, quantidade, replace_id }) {
      commit("replace_complemento", {
        ...rootGetters["ingrediente/ingredienteById"](replace_id),
        quantidade, old_id
      });
      dispatch("calculeBalanceamento")
    },
    add_complemento({ commit, dispatch, rootGetters }, { id, quantidade }) {
      if (quantidade) {
        let complemento = {
          ...rootGetters["ingrediente/ingredienteById"](id),
          quantidade: quantidade + "",
        };
        commit("add_complemento", complemento);
        dispatch("calculeBalanceamento")

      }
    },
    delete_complemento({ commit, dispatch }, complemento) {
      commit("delete_complemento", complemento);
      dispatch("calculeBalanceamento")

    },

    update_complemento({ commit, dispatch }, complemento) {
      commit("update_complemento", complemento);
      dispatch("calculeBalanceamento")

    }, async save_receita({ commit, state }) {
      const { id, receita } = await receitasService.saveReceita(state.receita);
      if (id && state.receita.id === null) commit("update_receita", { id });
      commit("update_receitas", { id, receita });
    },
    nova_receita({ commit }) {
      commit("nova_receita");
    },
    update_receita({ commit }, receita) {
      commit("update_receita", receita);
    },

    duplicate_receita({ commit }) {
      commit("duplicate_receita");
    },

    /*         success({ commit }, message) {
                    commit('success', message);
                }, */
  },
  mutations: {
    fetch_receitas(state, receitas) {
      state.receitas.length = 0;
      state.receitas.push(...receitas);
    },
    updateBalanceamento(state, balanceamento) {
      state.balanceamento = balanceamento;
    },
    load_receita_by_id(state, receita) {
      state.receita = receita;
    },
    add_ingrediente(state, ingrediente) {
      //ingrediente["ingrediente"] = ingrediente.id;

      state.receita.ingredientes.push(ingrediente);
    },
    update_ingrediente(state, { id, quantidade }) {
      const ingrediente = state.receita.ingredientes.filter(
        (v) => {
          return v.id == id
        }
      )[0];

      ingrediente.quantidade = quantidade;

    },
    replace_ingrediente(state, ingrediente) {

      const _ingrediente = state.receita.ingredientes.filter(
        (v) => {
          return v.id == ingrediente.old_id
        }
      )[0];
      Object.keys(_ingrediente).forEach((key)=>_ingrediente[key]=ingrediente[key]);
    },

    delete_ingrediente(state, ingrediente) {
      const index = state.receita.ingredientes.indexOf(ingrediente);
      state.receita.ingredientes.splice(index, 1);
    },
    add_complemento(state, complemento) {

      state.receita.complementos.push(complemento);
    },
    replace_complemento(state, complemento) {

      const _complemento = state.receita.complementos.filter(
        (v) => {
          return v.id == complemento.old_id
        }
      )[0];
      Object.keys(_complemento).forEach((key)=>_complemento[key]=complemento[key]);
    },
    update_complemento(state, { id, quantidade }) {

      const complemento = state.receita.complementos.filter(
        (v) => {
          return v.id == id
        }
      )[0];

      complemento.quantidade = quantidade;
    },
    delete_complemento(state, complemento) {
      const index = state.receita.complementos.indexOf(complemento);
      state.receita.complementos.splice(index, 1);
    },
    nova_receita(state) {
      state.balanceamento = {}
      state.receita.id = null
      state.receita.receita = ""
      state.receita.parametros = {}
      state.receita.ingredientes.length = 0
      if (state.receita.complementos) state.receita.complementos.length = 0

    },
    update_receita(state, { id, receita, complementos, parametros, tags }) {
      if (id) state.receita.id = id
      if (receita) state.receita.receita = receita
      if (complementos) {
        state.receita.complementos.length = 0
        state.receita.complementos.push(...complementos)
      }

      if (parametros) state.receita.parametros = { ...parametros }
      if (tags) {
        state.receita.tags.length = 0
        state.receita.tags.push(...tags)
      }

    },
    duplicate_receita(state) {
      const nome = state.receita.receita
      state.receita.id = null
      state.receita.receita = `${nome} #`;
    },
    update_receitas(state, { id, receita }) {
      const [_receita] = state.receitas.filter(({ id: _id }) => (id == _id))
      if (_receita) {
        _receita.id = id;
        _receita.receita = receita
      } else {
        state.receitas.push({ id, receita })
      }
    },
  },
};
