import { Api } from "./Api";

export const receitasService = {
  getReceitas,
  getReceitaById,
  saveReceita,
  calculeReceita
};

async function getReceitas() {
  return Api.post("/graphql", {
    query: `
           {    
            receitas {
              id
              receita
            }
         }`,
  })
    .then((response) => {
      return response.data.data.receitas;
    })
    .catch((err) => {
      throw err;
    });
}

async function calculeReceita(receita) {
  return Api.post("/graphql", {
    query: `
    mutation calculeReceita($ingredientes: [IngredienteItemInput!],$complementos: [IngredienteItemInput!]) {
      calculeReceita(ingredientes: $ingredientes,complementos: $complementos) {
        parametros {
          quantidade
          preco
          lactose
          gordura
          proteina
          vol_alcoolico
          calorias
          pod
          pac
          acucares
          solidos_diferentes
          solidos_totais
          sodio_mg
          temperatura
          fatorLiga
          fatorLactose
        }
        receita {
          id
          quantidade
          brix_medido
        }
      }
    }
    
    `,
    variables: {
      ingredientes: receita.ingredientes.map(
        ({ id,  quantidade, brix:brix_medido}) => {
          return { id, quantidade, brix_medido };
        }
      ),
      complementos: receita.complementos.map(
        ({ id,  quantidade}) => {
          return { id, quantidade };
        }
      ),
    },
  })
    .then((response) => {
      
      return response.data.data.calculeReceita;
    })
    .catch((err) => {
      throw err;
    });
}

async function saveReceita({id, receita, ingredientes,complementos, tags, parametros, }) {
  return Api.post("/graphql", {
    query: `
    mutation saveReceita($id: Int, $receita: String, $tags: [String], $parametros: ParametrosReceitaInput, $ingredientes: [IngredienteItemInput], $complementos: [IngredienteItemInput]) {
      saveReceita(id: $id, receita: $receita, tags: $tags, parametros: $parametros, ingredientes: $ingredientes, complementos: $complementos) {
        id
        receita
      }
    }    
    `,
    variables: {
      id,
      receita,
      tags,
      parametros,
      ingredientes:ingredientes.map(({id, quantidade,brix_medido})=>({id, quantidade,brix_medido})),
      complementos:complementos.map(({id, quantidade})=>({id, quantidade}))
    },
  })
    .then((response) => {
      return response.data.data.saveReceita;
    })
    .catch((err) => {
      throw err;
    });
}
async function getReceitaById(id) {
  return Api.post("/graphql", {
    query: `
    query receitas($id: Int) {
      receitas(id: $id) {
        id
        receita
        tags
        parametros {
          familia
          temperatura
        }
        ingredientes {
          id
          quantidade
        }
        complementos {
          id
          quantidade
        }
      }
    }
    
   `,
   variables:{id:Number(id)}
  })
    .then((response) => {
      return response.data.data.receitas[0];
    })
    .catch((err) => {
      throw err;
    });
}
