import { Api } from './Api'

export const userService = {
  signin,
  logout
}

async function signin(username, password) {

  return Api.post('/user/signin', {
    username,
    password
  }).then(response => {
    if (response.data.token) {
      localStorage.setItem('user', JSON.stringify(response.data))
    }
    return response.data
  }).catch(err=>{
    throw new Error(err.response.data.message)
  })
}
function logout() {
  localStorage.removeItem('user');
}


// function handleResponse(response) {
//     return response.text().then(text => {
//         const data = text && JSON.parse(text);
//         if
//     })
// }
