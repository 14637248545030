import { Api } from "./Api";

export const ingredientesService = {
  getIngredientes,
  getIngredienteById,
  saveIngrediente,
  deleteIngrediente,
  updatePrecoIngrediente
};
async function saveIngrediente(ingrediente) {
  return Api.post("/graphql", {
    query: `
    mutation saveIngrediente($ingrediente: InputIngrediente!) {
      saveIngrediente(ingrediente: $ingrediente) {
        id
        ingrediente
        tags
        preco
        acucares
        solidos_diferentes
        solidos_totais
        pod
        pac
        lactose
        frutose
        dextrose_glicose
        calorias
        vol_alcoolico
        sais
        polissacarideos
        fibra
        fibra_congelante
        gordura
        gordura_congelante
        proteina
        colesterol_mg
        gordura_saturada
        gordura_trans
        gordura_monoinsaturada
        gordura_poliinsaturada
        sodio_mg
        brix
        sacarose
        galactose
        maltose
      }
    }
    
    `,
    variables: { ingrediente },
  })
    .then((response) => {
      return response.data.data.saveIngrediente;
    })
    .catch((err) => {
      throw err;
    });
}
async function updatePrecoIngrediente({id, preco}) {
  return Api.post("/graphql", {
    query: `
    mutation updatePrecoIngrediente($id: Int!, $preco:Decimal!) {
      updatePrecoIngrediente (id: $id, preco: $preco) {
        id
        preco
      }
    }
    `,
    variables: { id, preco },
  })
    .then((response) => {
      return response.data.data.updatePrecoIngrediente;
    })
    .catch((err) => {
      throw err;
    });
}
async function deleteIngrediente(id) {
  return Api.post("/graphql", {
    query: `
    mutation deleteIngrediente($id: ObjectId!) {
      deleteIngrediente (id: $id) {
        _id
        nome
        tipo
        grupo
        preco
        sacarose
        frutose
        glicose
        maltose
        lactose
        galactose
        caseina
        gordura
        lecitina
        alcool
        cacauPuro
        manteigaCacau
        solidosGordura
        sngl
        ph      
        brix
        solidosAcucar
        solidosTotais
        pe
        pac
        fpdf
        maximoRecomendado
      }
    }
    `,
    variables: { id },
  })
    .then((response) => {
      return response.data.data.deteleIngrediente;
    })
    .catch((err) => {
      throw err;
    });
}
async function getIngredienteById(id) {
  return Api.post("/graphql", {
    query: `
      query ingrediente($id: Int) {
        ingrediente(id: $id) {
          id
          ingrediente
          tags
          acucares
          solidos_diferentes
          solidos_totais
          pod
          pac
          lactose
          frutose
          dextrose_glicose
          calorias
          vol_alcoolico
          sais
          polissacarideos
          fibra
          fibra_congelante
          gordura
          gordura_congelante
          proteina
          colesterol_mg
          gordura_saturada
          gordura_trans
          gordura_monoinsaturada
          gordura_poliinsaturada
          sodio_mg
          brix
          sacarose
          galactose
          maltose
        }
      }      
`,
    variables: {id},
  })
    .then((response) => {
      return response.data.data.ingrediente;
    })
    .catch((err) => {
      throw err;
    });
}
async function getIngredientes(filtro = {}) {
  filtro["hideDeleted"] = true;
  return Api.post("/graphql", {
    query: `query ingredientes {
      ingredientes {
        id
        ingrediente
        preco
        brix
      }
    }`,
    variables: filtro,
  })
    .then((response) => {
      return response.data.data.ingredientes;
    })
    .catch((err) => {
      throw err;
    });
}
