import axios from "axios";
import store from "./../store";
// import { authHeader } from '../helpers';

// export default() => {
//     return axios.create({
//         baseURL: `http://localhost:3000/api`,
//         timeout:1000,
//         withCredentials: false,
//         headers: {
//             'Accept': 'application/json',
//             'Content-Type': 'application/json',
//             ...authHeader()
//         }
//     })

// }
// const {loggedIn, token} = mapGetters({
//     'loggedIn':'authentication/loggedIn',
//     'token':'authentication/token'
// })
/* function loggedIn() {
  return store.getters['authentication/loggedIn']
} */
function getToken() {
  return store.getters['authentication/token']
}
function getRefreshToken() {
  return store.getters['authentication/refreshToken']
}
const http = axios.create({
  baseURL: `https://dash.gelafrutta.com.br`,
  //baseURL: `${process.env.API_BASEURL}`,
  // baseURL: `http://localhost:3001`,
  // timeout: 10000,
  withCredentials: false,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
    // ...authHeader()
  },
  // validateStatus: function (status) {
  //     return status >= 200 && status < 500; // default
  // }
});
http.interceptors.request.use(
  (config) => {
    const token = getToken()
    if (token) {
      // config.headers["Authorization"] = 'Bearer ' + token;  // for Spring Boot back-end
      config.headers["authorization"] = `Bearer ${token}`; // for Node.js Express back-end
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
)
http.interceptors.response.use(
  (res) => {
    return res;
  },
  async (err) => {

    const originalConfig = err.config;

    if (!["/user/signin", "/user/refresh-token"].includes(originalConfig.url) && err.response) {
      // Access Token was expired
      if (err.response.status === 401 && !originalConfig._retry) {
        originalConfig._retry = true;

        try {
          const rs = await http.post("/user/refresh-token", {
            refresh_token: getRefreshToken()
          });

          const { token, refreshToken } = rs.data;
          await store.dispatch('authentication/refreshToken', { token, refreshToken });

          return http(originalConfig);
        } catch (_error) {
          store.dispatch('authentication/logout')
          return Promise.reject(_error);
        }
      }
    }

    return Promise.reject(err);
  }

)

export const Api = http;
