<template>
  <v-app>
    <v-app-bar v-if="loggedIn" app color="primary" dark>
      <div class="d-flex align-center">
        <v-btn to="/about"> <span class="mr-2">About</span> </v-btn>
        <v-btn to="/financeiro">
          <span class="mr-2">Financeiro</span>
        </v-btn>
        <v-btn to="/ingredientes">
          <span class="mr-2">Ingredientes</span>
        </v-btn> 
      <v-btn to="/receita">
          <span class="mr-2">Receita</span>
        </v-btn>
      </div>

      <v-spacer></v-spacer>


<v-menu left bottom>
        <template v-slot:activator="{ on }">
          <v-btn icon v-on="on">
            <v-icon>mdi-dots-vertical</v-icon>
          </v-btn>
        </template>
      <v-list>
        <v-list-item>
          <v-list-item-title>{{ user.name }}</v-list-item-title>
        </v-list-item>
        <!-- <v-list-item v-for="n in 5" :key="n" @click="() => {}">
          <v-list-item-title>Option {{ n }}</v-list-item-title>
        </v-list-item> -->
        <v-list-item @click="logout">
          <v-list-item-title>Sair</v-list-item-title>
        </v-list-item>
      </v-list></v-menu>
    </v-app-bar>

    <v-main>    <router-view />
    </v-main>
  </v-app>
</template>
<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: left;
  color: #2c3e50;
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
<script>
import { mapGetters, mapActions } from "vuex";
export default {
  name: "App",


  data: () => ({
    //6
  }),
  computed:{
    ...mapGetters({
      loggedIn: "authentication/loggedIn",
      user: "authentication/user",
    }),
  },
  methods: {
    ...mapActions({
      logout: "authentication/logout",
    }),
  },
  async beforeCreate() {
   await this.$store.dispatch("init");
  },
};
</script>
