const { ingredientes } = require("./ingredientes.data");
const { ingredientesService } = require("./../services");
export const ingrediente = {
  namespaced: true,
  state: {
    produtos: ingredientes,
    ingredientes: [],
    receita: [],
    receitaTotal: 0,
  },
  getters: {
    ingredientes: (state) => {
      return state.ingredientes.sort(
        ({ ingrediente: a }, { ingrediente: b }) => {
          return a < b ? -1 : a > b ? 1 : 0;
        }
      );
    },
    ingredienteById: (state) => (id) => {
      return state.ingredientes.filter((v) => v.id == id)[0];
    },
    produtos: (state) => {
      return state.produtos;
    },
    produtoById: (state) => (id) => {
      return state.produtos.filter((v) => v.id == id)[0];
    },
    receita: (state) => {
      return state.receita;
    },
    receitaEmVolume: (state) => (volume) => {
      let total = 0;
      state.receita.forEach((v) => (total += +v.quantity));
      return state.receita.map((ingrediente) => ({
        ...ingrediente,
        quantity: ((ingrediente.quantity * volume) / total).toFixed(2),
      }));
    },
    balanceamento: (state) => {
      let formula = {
        fatSolids: 0,
        nonFatSolids: 0,
        sugarSolids: 0,
        totalSolids: 0,
        pac: 0,
        pe: 0,
      };

      if (state.receita.length) {
        let totalQuantity = 0,
          _itemDosage = 0;

        state.receita.forEach((ingredient) => {
          if (ingredient.quantity <= 0) return false;
          Object.keys(ingredient).forEach((prop) => {
            // eslint-disable-next-line
            if (formula.hasOwnProperty(prop)) {
              _itemDosage = ingredient[prop] * ingredient.quantity;
              formula[prop] += _itemDosage;
            }
          });
          totalQuantity = totalQuantity + parseInt(ingredient.quantity);
        });
        Object.keys(formula).forEach((prop) => {
          formula[prop] = ((formula[prop] / totalQuantity) * 100).toFixed(2);
        });
      }
      return formula;
    },
  },

  actions: {
    async fetch_ingredientes({ commit }) {
      let ingredientes = await ingredientesService.getIngredientes();

      commit("fetch_ingredientes", ingredientes);
    },
    async update_preco({ commit }, ingrediente) {
      let precoAtualizado = await ingredientesService.updatePrecoIngrediente(
        ingrediente
      );
      commit("update_preco", precoAtualizado);
    },
    add_produto({ commit }, produto) {
      commit("add_produto", produto);
    },
    async save_ingrediente({ commit }, ingrediente) {
      let ingredienteSalvo = await ingredientesService.saveIngrediente(
        ingrediente
      );

      commit("save_ingrediente", ingredienteSalvo);
    },
    async delete_ingrediente({ commit }, { _id: id }) {
      await ingredientesService.deleteIngrediente(id);
      commit("delete_ingrediente", id);
    },

    update_ingrediente({ commit }, { id, quantity }) {
      commit("update_ingrediente", { id, quantity });
    },

    /*         success({ commit }, message) {
                    commit('success', message);
                }, */
  },
  mutations: {
    fetch_ingredientes(state, ingredientes) {
      state.ingredientes.length = 0;
      state.ingredientes.push(...ingredientes);
    },
    add_produto(state, produto) {
      state.produtos.push(produto);
    },
    save_ingrediente(state, ingrediente) {
      let ingredienteSalvo = state.ingredientes.filter(
        (v) => v.id == ingrediente.id
      )[0];
      if (ingredienteSalvo) {
        Object.assign(ingredienteSalvo, ingrediente);
      } else {
        state.ingredientes.push(ingrediente);
      }
    },
    delete_ingrediente(state, id) {
      if (id) {
        const ingredienteSalvo = state.ingredientes.filter(
          (v) => v.id == id
        )[0];
        const index = state.ingredientes.indexOf(ingredienteSalvo);
        state.ingredientes.splice(index, 1);
      }
    },
    update_ingrediente(state, { id, quantity }) {
      const ingrediente = state.receita.filter((v) => v.id == id)[0];
      ingrediente.quantity = quantity;
    },
    update_preco(state, { id, preco }) {
      const ingrediente = state.ingredientes.filter((v) => v.id == id)[0];
      ingrediente.preco = preco;
    },
    save_receita(state) {
      localStorage.setItem("receita", JSON.stringify(state.receita));
    },
    load_receita(state) {
      state.receita.length = [];
      state.receita.push(...JSON.parse(localStorage.getItem("receita")));
    },
    /*         success(state, message) {
                    state.type ='alert-success';
                    state.message = message
                }, */
  },
};
