export const ingredientes = [
  {
    id: 564,
    name: "AÇÚCAR (SACAROSE)",
    fatSolids: 0,
    nonFatSolids: 0,
    sugarSolids: 1,
    totalSolids: 1,
    product: null,
    pe: 1,
    pac: 1,
    type: "solid",
  },
  {
    id: 565,
    name: "AÇÚCAR INVERTIDO 80°BRIX",
    fatSolids: 0,
    nonFatSolids: 0,
    sugarSolids: 0.8,
    totalSolids: 0.8,
    product: null,
    pe: 1.25,
    type: "liquid",
  },
  {
    id: 566,
    name: "AÇÚCAR INVERTIDO 70°BRIX",
    fatSolids: 0,
    nonFatSolids: 0,
    sugarSolids: 0.7,
    totalSolids: 0.7,
    product: null,
    pe: 1.3,
    pac: 1.9,
    type: "liquid",
  },

  {
    id: 553,
    name: "ÁGUA",
    fatSolids: 0,
    nonFatSolids: 0,
    sugarSolids: 0,
    totalSolids: 0,
    product: null,
    pe: 0,
    pac: 0,
    type: "liquid",
  },
  {
    id: 585,
    name: "AQUA 5",
    fatSolids: 0,
    nonFatSolids: 1,
    sugarSolids: 0,
    totalSolids: 1,
    product: null,
    type: "solid",
  },
  {
    id: 562,
    name: "BASE GELATO ACQUA",
    fatSolids: 0.24,
    nonFatSolids: 0.21,
    sugarSolids: 0.53,
    totalSolids: 0.98,
    product: null,
    type: "solid",
  },
  {
    id: 563,
    name: "BASE GELATO LATTE",
    fatSolids: 0.2,
    nonFatSolids: 0.55,
    sugarSolids: 0.23,
    totalSolids: 0.98,
    product: null,
    type: "solid",
  },
  {
    id: 594,
    name: "CACAU EM PÓ 10/12",
    fatSolids: 0.11,
    nonFatSolids: 0.89,
    sugarSolids: 0,
    totalSolids: 1,
    product: null,
    type: "solid",
  },
  {
    id: 595,
    name: "CACAU EM PÓ 22/24",
    fatSolids: 0.23,
    nonFatSolids: 0.77,
    sugarSolids: 0,
    totalSolids: 1,
    product: null,
    type: "solid",
  },
  {
    id: 572,
    name: "CREME DE LEITE (18% GORDURA)",
    fatSolids: 0.18,
    nonFatSolids: 0.073,
    sugarSolids: 0,
    totalSolids: 0.253,
    product: null,
    type: "liquid",
  },
  {
    id: 871,
    name: "CREME DE LEITE CCGL (20% GORDURA)",
    fatSolids: 0.2,
    nonFatSolids: 0.053,
    sugarSolids: 0,
    totalSolids: 0.253,
    product: null,
    pe: 0,
    pac: 0.0265,
    type: "liquid",
  },
  {
    id: 573,
    name: "CREME DE LEITE (25% GORDURA)",
    fatSolids: 0.25,
    nonFatSolids: 0.067,
    sugarSolids: 0,
    totalSolids: 0.317,
    product: null,
    type: "liquid",
  },
  {
    id: 574,
    name: "CREME DE LEITE (35% GORDURA)",
    fatSolids: 0.35,
    nonFatSolids: 0.06,
    sugarSolids: 0,
    totalSolids: 0.41,
    product: null,
    type: "liquid",
  },
  {
    id: 575,
    name: "CREME DE LEITE (40% GORDURA)",
    fatSolids: 0.4,
    nonFatSolids: 0.54,
    sugarSolids: 0,
    totalSolids: 0.454,
    product: null,
    type: "liquid",
  },
  {
    id: 576,
    name: "CREME DE LEITE (52% GORDURA)",
    fatSolids: 0.52,
    nonFatSolids: 0.045,
    sugarSolids: 0,
    totalSolids: 0.565,
    product: null,
    type: "liquid",
  },
  {
    id: 877,
    name: "Doce de Leite Veneza",
    fatSolids: 0.1,
    nonFatSolids: 0.1,
    sugarSolids: 0.45,
    totalSolids: 0.65,
    product: null,
    type: "liquid",
  },
  {
    id: 878,
    name: "Cheam Chesse Tirolez",
    fatSolids: 0.26,
    nonFatSolids: 0.0933,
    sugarSolids: 0,
    totalSolids: 0.353,
    product: null,
    type: "liquid",
  },
  {
    id: 589,
    name: "EMULSIFICANTE SELECTA",
    fatSolids: 0.23,
    nonFatSolids: 0,
    sugarSolids: 0,
    totalSolids: 0.23,
    product: null,
    type: "liquid",
  },
  {
    id: 587,
    name: "EMUSTAB",
    fatSolids: 0.23,
    nonFatSolids: 0,
    sugarSolids: 0,
    totalSolids: 0.23,
    product: null,
    type: "liquid",
  },
  {
    id: 588,
    name: "EMUSTAB DR",
    fatSolids: 0.23,
    nonFatSolids: 0,
    sugarSolids: 0,
    totalSolids: 0.23,
    product: null,
    type: "liquid",
  },
  {
    id: 593,
    name: "Es- ICE 4",
    fatSolids: 0.5,
    nonFatSolids: 0.5,
    sugarSolids: 0,
    totalSolids: 1,
    product: null,
    type: "solid",
  },
  {
    id: 596,
    name: "EXTRATO DE MALTE",
    fatSolids: 0,
    nonFatSolids: 1,
    sugarSolids: 0,
    totalSolids: 1,
    product: null,
    type: "solid",
  },
  {
    id: 866,
    name: "GLICOSE PÓ",
    fatSolids: 0,
    nonFatSolids: 0,
    sugarSolids: 0.95,
    totalSolids: 0.95,
    product: null,
    pe: 0.5,
    pac: 0.6,
    type: "solid",
  },
  {
    id: 567,
    name: "DEXTROSE PÓ",
    fatSolids: 0,
    nonFatSolids: 0,
    sugarSolids: 0.92,
    totalSolids: 0.92,
    product: null,
    pe: 0.7,
    pac: 1.8,
    type: "solid",
  },
  {
    id: 599,
    name: "Inulina",
    fatSolids: 0,
    nonFatSolids: 1,
    sugarSolids: 0,
    totalSolids: 1,
    product: null,
    pe: 0,
    pac: 0.25,
    type: "solid",
  },
  {
    id: 569,
    name: "GORDURA VEGETAL DE COCO",
    fatSolids: 1,
    nonFatSolids: 0,
    sugarSolids: 0,
    totalSolids: 1,
    product: null,
    type: "liquid",
  },
  {
    id: 570,
    name: "GORDURA VEGETAL DE PALMA",
    fatSolids: 1,
    nonFatSolids: 0,
    sugarSolids: 0,
    totalSolids: 1,
    product: null,
    type: "liquid",
  },
  {
    id: 568,
    name: "GORDURA VEGETAL HIDROGENADA",
    fatSolids: 1,
    nonFatSolids: 0,
    sugarSolids: 0,
    totalSolids: 1,
    product: null,
    type: "liquid",
  },
  {
    id: 592,
    name: "H-2000",
    fatSolids: 0.38,
    nonFatSolids: 0.62,
    sugarSolids: 0,
    totalSolids: 1,
    product: null,
    type: "solid",
  },
  {
    id: 586,
    name: "LAQUA 10",
    fatSolids: 0.338,
    nonFatSolids: 0.61,
    sugarSolids: 0,
    totalSolids: 0.95,
    product: null,
    type: "solid",
  },
  {
    id: 555,
    name: "LEITE DESNATADO IN NATURA",
    fatSolids: 0.01,
    nonFatSolids: 0.08,
    sugarSolids: 0,
    totalSolids: 0.09,
    product: null,
    pe: 0,
    pac: 0.04,
    type: "liquid",
  },
  {
    id: 557,
    name: "LEITE EM PÓ DESNATADO",
    fatSolids: 0.01,
    nonFatSolids: 0.97,
    sugarSolids: 0,
    totalSolids: 0.98,
    product: null,
    pe: 0,
    pac: 0.473,
    type: "solid",
  },
  {
    id: 556,
    name: "LEITE EM PÓ INTEGRAL",
    fatSolids: 0.26,
    nonFatSolids: 0.72,
    sugarSolids: 0,
    totalSolids: 0.98,
    product: null,
    pe: 0.0608,
    pac: 0.355,
    type: "solid",
  },
  {
    id: 554,
    name: "LEITE INTEGRAL IN NATURA",
    fatSolids: 0.03,
    nonFatSolids: 0.09,
    sugarSolids: 0,
    totalSolids: 0.12,
    product: null,
    pe: 0.0072,
    pac: 0.045,
    type: "liquid",
  },
  {
    id: 857,
    name: "LEITE UHT 3%",
    fatSolids: 0.03,
    nonFatSolids: 0.09,
    sugarSolids: 0,
    totalSolids: 0.12,
    product: null,
    pe: 0.0074,
    pac: 0.045,
    type: "liquid",
  },
  {
    id: 584,
    name: "LIGA EXTRA 2",
    fatSolids: 0,
    nonFatSolids: 1,
    sugarSolids: 0,
    totalSolids: 1,
    product: null,
    type: "solid",
  },
  {
    id: 583,
    name: "LIGA EXTRA INDUSTRIAL",
    fatSolids: 0,
    nonFatSolids: 1,
    sugarSolids: 0,
    totalSolids: 1,
    product: null,
    type: "solid",
  },
  {
    id: 597,
    name: "GLICOSE LIQUIDA",
    fatSolids: 0,
    nonFatSolids: 0.8,
    sugarSolids: 0,
    totalSolids: 0.8,
    product: null,
    pe: 0.5,
    pac: 0.6,
    type: "solid",
  },
  {
    id: 598,
    name: "MALTODEXTRINA DE 18",
    fatSolids: 0,
    nonFatSolids: 0.95,
    sugarSolids: 0,
    totalSolids: 0.95,
    product: null,
    pe: 0.3,
    pac: 0.4,
    type: "solid",
  },
  {
    id: 571,
    name: "MANTEIGA SEM SAL",
    fatSolids: 0.83,
    nonFatSolids: 0.015,
    sugarSolids: 0,
    totalSolids: 0.845,
    product: null,
    pe: 0,
    pac: 0.007,
    type: "liquid",
  },
  {
    id: 578,
    name: "MAX CREAM",
    fatSolids: 0.591,
    nonFatSolids: 0.356,
    sugarSolids: 0,
    totalSolids: 0.947,
    product: null,
    type: "solid",
  },
  {
    id: 581,
    name: "REAL SABOR",
    fatSolids: 0,
    nonFatSolids: 0.68,
    sugarSolids: 0.3,
    totalSolids: 0.98,
    product: null,
    type: "solid",
  },
  {
    id: 560,
    name: "SELECTA CREAM",
    fatSolids: 0.26,
    nonFatSolids: 0.66,
    sugarSolids: 0.15,
    totalSolids: 0.98,
    product: null,
    type: "solid",
  },
  {
    id: 559,
    name: "SELECTA CREAM  SC",
    fatSolids: 0.17,
    nonFatSolids: 0.62,
    sugarSolids: 0.15,
    totalSolids: 0.996,
    product: null,
    type: "solid",
  },
  {
    id: 561,
    name: "SELECTA CREAM LIVRE TRANS",
    fatSolids: 0.003,
    nonFatSolids: 0.68,
    sugarSolids: 0.3,
    totalSolids: 0.983,
    product: null,
    type: "solid",
  },
  {
    id: 579,
    name: "SELECTA LIGHT",
    fatSolids: 0,
    nonFatSolids: 0.98,
    sugarSolids: 0,
    totalSolids: 0.98,
    product: null,
    type: "solid",
  },
  {
    id: 558,
    name: "SORO DE LEITE EM PÓ",
    fatSolids: 0.01,
    nonFatSolids: 0.96,
    sugarSolids: 0,
    totalSolids: 0.97,
    product: null,
    pe: 0,
    pac: 0.48,
    type: "solid",
  },
  {
    id: 580,
    name: "SOYA ICE",
    fatSolids: 0,
    nonFatSolids: 0.68,
    sugarSolids: 0.3,
    totalSolids: 0.98,
    product: null,
    type: "solid",
  },
  {
    id: 582,
    name: "SUPER LIGA NEUTRA",
    fatSolids: 0,
    nonFatSolids: 1,
    sugarSolids: 0,
    totalSolids: 1,
    product: null,
    type: "solid",
  },
  {
    id: 577,
    name: "TOPPING",
    fatSolids: 0.28,
    nonFatSolids: 0.5,
    sugarSolids: 0.2,
    totalSolids: 0.98,
    product: null,
    type: "solid",
  },
  {
    id: 590,
    name: "UF-10",
    fatSolids: 0.35,
    nonFatSolids: 0.65,
    sugarSolids: 0,
    totalSolids: 1,
    product: null,
    type: "solid",
  },
  {
    id: 867,
    name: "XAROPE DE GLICOSE DE 38",
    fatSolids: 0,
    nonFatSolids: 0,
    sugarSolids: 0.8,
    totalSolids: 0.8,
    product: null,
    pe: 0.5,
    pac: 0.6,
    type: "liquid",
  },
  {
    id: 591,
    name: "XP-3000",
    fatSolids: 0.43,
    nonFatSolids: 0.57,
    sugarSolids: 0,
    totalSolids: 1,
    product: null,
    type: "solid",
  },
  {
    id: 700,
    name: "Acaí Sempre Viva  verificar",
    fatSolids: 0.2,
    nonFatSolids: 0,
    sugarSolids: 0.3,
    totalSolids: 0.5,
    product: null,
    type: "fruit",
  },
  {
    id: 701,
    name: "Graviola Sempre Viva",
    fatSolids: 0,
    nonFatSolids: 0,
    sugarSolids: 0.3,
    totalSolids: 0.5,
    product: null,
    type: "fruit",
  },
  {
    id: 702,
    name: "Acaí Brasfrut",
    fatSolids: 0.039,
    nonFatSolids: 0.034,
    sugarSolids: 0.062,
    totalSolids: 0.135,
    product: null,
    type: "fruit",
  },
  {
    id: 703,
    name: "Abacaxi Sempre Viva",
    fatSolids: 0,
    nonFatSolids: 0.012,
    sugarSolids: 0.12,
    totalSolids: 0.132,
    product: null,
    type: "fruit",
  },
  {
    id: 704,
    name: "Umbu Sempre Viva",
    fatSolids: 0,
    nonFatSolids: 0.021,
    sugarSolids: 0.11,
    totalSolids: 0.131,
    product: null,
    type: "fruit",
  },
  {
    id: 705,
    name: "Tangerina Sempre Viva",
    fatSolids: 0.009,
    nonFatSolids: 0.139,
    sugarSolids: 0.12,
    totalSolids: 0.268,
    product: null,
    type: "fruit",
  },
  {
    id: 706,
    name: "Cajá Sempre Viva",
    fatSolids: 0.009,
    nonFatSolids: 0.04064,
    sugarSolids: 0.115,
    totalSolids: 0.16464,
    product: null,
    type: "fruit",
  },
  {
    id: 707,
    name: "Manga Sempre Viva",
    fatSolids: 0.009,
    nonFatSolids: 0.028,
    sugarSolids: 0.142,
    totalSolids: 0.179,
    product: null,
    type: "fruit",
  },
  {
    id: 708,
    name: "Cajú Sempre Viva",
    fatSolids: 0.009,
    nonFatSolids: 0.06,
    sugarSolids: 0.06,
    totalSolids: 0.129,
    product: null,
    type: "fruit",
  },
  {
    id: 709,
    name: "Melância",
    fatSolids: 0.001,
    nonFatSolids: 0.029,
    sugarSolids: 0.07,
    totalSolids: 0.1,
    product: null,
    type: "fruit",
  },
  {
    id: 710,
    name: "Cupuaçu",
    fatSolids: 0.001,
    nonFatSolids: 0.127,
    sugarSolids: 0.042,
    totalSolids: 0.17,
    product: null,
    type: "fruit",
  },
  {
    id: 711,
    name: "Abacate",
    fatSolids: 0.26,
    nonFatSolids: 0.042,
    sugarSolids: 0.02,
    totalSolids: 0.304,
    product: null,
    type: "fruit",
  },
  {
    id: 712,
    name: "Maracujá",
    fatSolids: 0.007,
    nonFatSolids: 0.053,
    sugarSolids: 0.045,
    totalSolids: 0.105,
    product: null,
    type: "fruit",
  },
  {
    id: 713,
    name: "Abóbora",
    fatSolids: 0.0,
    nonFatSolids: 0.045,
    sugarSolids: 0.105,
    totalSolids: 0.15,
    product: null,
    type: "fruit",
  },
  {
    id: 714,
    name: "Tamarindo",
    fatSolids: 0.0,
    nonFatSolids: 0.3293,
    sugarSolids: 0.31864,
    totalSolids: 0.64794,
    product: null,
    type: "fruit",
  },
  {
    id: 715,
    name: "Morango",
    fatSolids: 0.004,
    nonFatSolids: 0.026,
    sugarSolids: 0.08,
    totalSolids: 0.11,
    product: null,
    type: "fruit",
  },
  {
    id: 716,
    name: "Limão - suco",
    fatSolids: 0.0,
    nonFatSolids: 0.07,
    sugarSolids: 0.03,
    totalSolids: 0.1,
    product: null,
    type: "fruit",
  },
  {
    id: 717,
    name: "Abacaxi",
    fatSolids: 0.002,
    nonFatSolids: 0.038,
    sugarSolids: 0.12,
    totalSolids: 0.16,
    product: null,
    type: "fruit",
  },
  {
    id: 718,
    name: "Leite de coco caseiro",
    fatSolids: 0.23,
    nonFatSolids: 0.0449,
    sugarSolids: 0.0554,
    totalSolids: 0.3303,
    product: null,
    type: "fruit",
  },
  {
    id: 719,
    name: "Polpa Cacau - Sempre Viva",
    fatSolids: 0.0,
    nonFatSolids: 0.038,
    sugarSolids: 0.155,
    totalSolids: 0.193,
    product: null,
    type: "fruit",
  },
  {
    id: 720,
    name: "Polpa de acerola",
    fatSolids: 0.0,
    nonFatSolids: 0.038,
    sugarSolids: 0.063,
    totalSolids: 0.101,
    product: null,
    pac: 0.063,
    pe: 0.063,
    type: "fruit",
  },

  {
    id: 721,
    name: "Polpa de cajá",
    fatSolids: 0.0,
    nonFatSolids: 0.038,
    sugarSolids: 0.087,
    totalSolids: 0.125,
    product: null,
    pac: 0.087,
    pe: 0.087,
    type: "fruit",
  },
  {
    id: 722,
    name: "Polpa de cajú",
    fatSolids: 0.0,
    nonFatSolids: 0.038,
    sugarSolids: 0.098,
    totalSolids: 0.136,
    pac: 0.098,
    pe: 0.098,
    product: null,
    type: "fruit",
  },
  {
    id: 781,
    name: "Banana prata",
    fatSolids: 0.001,
    nonFatSolids: 0.033,
    sugarSolids: 0.26,
    totalSolids: 0.294,
    product: null,
    type: "fruit",
  },
  {
    id: 782,
    name: "Pasta de amendoim Luke",
    fatSolids: 0.4933,
    nonFatSolids: 0.453,
    sugarSolids: 0.04,
    totalSolids: 0.9863,
    product: null,
    pac: 0.21,
    pe: 0.21,
    type: "fruit",
  },
  {
    id: 801,
    name: "Chocolate AMMA 75%",
    fatSolids: 0.2,
    nonFatSolids: 0,
    sugarSolids: 0.3,
    totalSolids: 0.5,
    product: null,
    type: "solid",
  },
  {
    id: 802,
    name: "Chocolate Callebaut 70.5%",
    fatSolids: 0.388,
    nonFatSolids: 0.26,
    sugarSolids: 0.3,
    totalSolids: 0.948,
    pe: 0.3,
    pac: 0.3,
    product: null,
    type: "solid",
  },
  {
    id: 803,
    name: "Chocolate Sicao Meio Amargo",
    fatSolids: 0.32,
    nonFatSolids: 0.12,
    sugarSolids: 0.49,
    totalSolids: 0.93,
    pe: 0.49,
    pac: 0.49,
    product: null,
    type: "solid",
  },
];
