import { userService } from '../services';

import router from '../router';

const user = JSON.parse(localStorage.getItem('user'));
const initialState = user
    ? { status: { loggedIn: true }, user }
    : { status: { loggedIn: false }, user: null };

export const authentication = {
    namespaced: true,
    state: initialState,
    getters: {
        user: ({  user: { name, username }  }) => {
            return { name, username }
        },
        loggedIn: (state) => {
            return state.status.loggedIn;
        },
        token: (state) => {
            return state.user ? state.user.token : ''
        },
        refreshToken: (state) => {
            return state.user ? state.user.refreshToken : ''
        }
    },
    actions: {
        async refreshToken({ commit }, authorization) {
             await commit('refreshToken', authorization);
        },

        async signin({ dispatch, commit }, { username, password }) {
            try {
                const user =await  userService.signin(username, password)
                commit('set_user', user);
                dispatch('init', null, { root: true });
                return user
            } catch (error) {
                commit('clean_user', error);
                throw new Error(error)
            }

        },
        logout({ commit }) {
            localStorage.removeItem('user');
            // userService.logout();
            commit('clean_user')
            router.push('/login')
        }
    },
    mutations: {
        async refreshToken(state, { token, refreshToken }) {
            state.status.loggedIn = true;
            state.user.token = token;
            if (refreshToken) state.user.refreshToken = refreshToken;
            localStorage.setItem('user', JSON.stringify(state.user))
            return
        },

        clean_user(state) {
            state.status.loggedIn = false;
            state.user = null;
        },
        set_user(state,  user ) {

            state.status.loggedIn = !!user.token;
            state.user = user;
        }

    }
}
