import Vue from "vue";
import Vuex from "vuex";
import { ingrediente } from "./ingredientes";
import { receita } from "./receitas";
import { financeiro } from "./financeiro";
import { authentication } from "./authentication";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {
    async init({ dispatch, rootGetters  }) {
      const logeedIn = rootGetters['authentication/loggedIn']
      if(!logeedIn) return
      const initCalls = [
        "financeiro/fetch_contas",
        "financeiro/fetch_transacoes",
        "financeiro/fetch_periodos",
      ];
      //"authentication/restore",
      for (const actionCall of initCalls) {
        await dispatch(actionCall, null, { root: true });
      }
    },
  },
  modules: {
    ingrediente,
    receita,
    financeiro,
    authentication
  },
});
