<template>
  <v-container v-if="!loggedIn" fluid>
    <v-row>
      <v-col cols="6" offset="5">
        <v-card class="pa-5">
          <form name="form" @submit.prevent="handleLogin">
            <v-text-field
              v-model="username"
              label="Usuário"
              :rules="[vUsername]"
            ></v-text-field>
            <v-text-field
              type="password"
              v-model="password"
              label="Senha"
              :rules="[vPassword]"
            ></v-text-field>
            <v-alert v-if="hasError" dense type="error">
              {{ message }}
            </v-alert>
            <v-card-actions>
              <v-row>
                <v-spacer></v-spacer>
                <v-btn
                  @click="handleLogin"
                  text
                  outlined
                  color="blue darken-1"
                  :disabled="loading"
                  >Login</v-btn
                >
              </v-row>
            </v-card-actions>
            <div class="hidden-submit"><input type="submit" tabindex="-1"/></div>

          </form>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<style scoped>
.hidden-submit {
    border: 0 none;
    height: 0;
    width: 0;
    padding: 0;
    margin: 0;
    overflow: hidden;
}
</style>
<script>
import { mapActions, mapGetters } from "vuex";
// @ is an alias to /src

export default {
  name: "login",
  data() {
    return {
      username: "",
      password: "",
      loading: false,
      message: "",
      submitted: false,
    };
  },
  watch:{
      username:function() {
          this.message=''
      },
      password:function() {
          this.message=''
      }
  },
  computed: {
    vUsername() {
      return this.submitted && !this.username
        ? "Digite o nome de usuário"
        : true;
    },
    hasError() {
      return this.message.length > 0;
    },
    vPassword() {
      return this.submitted && !this.password ? "Digite a senha" : true;
    },
    ...mapGetters({
      loggedIn: "authentication/loggedIn",
    }),
  },
  created() {
    if (this.loggedIn) {
      this.$router.push("/");
    }
  },
  methods: {
    ...mapActions({
      login: "authentication/signin",
    }),
    handleSubmit() {
      this.submitted = true;
      const { username, password } = this;
      if (username && password) {
        this.login({ username, password });
      }
    },
    handleLogin() {
      this.loading = true;
      this.submitted = true;
      if (this.username && this.password) {
        this.login({ username: this.username, password: this.password }).then(
          () => {
            this.$router.push("/");
          },
          (error) => {
            this.loading = false;
            this.message = String(error);
            /* (error.response && error.response.data) ||
              error.message ||
              error.toString(); */
          }
        );
      }
    },
  },
};
</script>
