const { contasService, transacoesService } = require("./../services");

const meses = [
  "",
  "JAN",
  "FEV",
  "MAR",
  "ABR",
  "MAI",
  "JUN",
  "JUL",
  "AGO",
  "SET",
  "OUT",
  "NOV",
  "DEZ",
];
export const financeiro = {
  namespaced: true,
  state: {
    _contas: [],
    _periodos: [],
    _conta_map: new Map(),
    _transacoes: [],
    _balanco: [],
  },
  getters: {
    contas: (state) => {
      return state._contas;
    },
    balanco: (state, getters) => {
      return state._balanco.map(({ _account, debito, credito }) => {
        return {
          nome: getters.contasById(_account).nome,
          natureza: getters.contasById(_account).natureza,
          debito,
          credito,
        };
      });
    },
    periodos: (state) => {
      return state._periodos.map(({ periodo, mes, ano }) => {
        return { periodo, mes_ext: meses[mes], mes, ano };
      });
    },
    contasById: (state) => (id) => {
      return state._conta_map.get(id);
    },
    transacoes: (state, getters) => {
      let transacoes = new Map();
      state._transacoes.forEach(
        ({ _id, _debit_account, _credit_account, date, amount, memo }) => {
          transacoes.set(_id, {
            _id,
            para: getters.contasById(_debit_account).nome,
            de: getters.contasById(_credit_account).nome,
            date: date
              .split("T")[0]
              .split("-")
              .reverse()
              .join("/"),
            amount,
            memo,
          });
        }
      );
      return [...transacoes.values()];
    },
  },

  actions: {
    async fetch_contas({ commit }) {
      let contas = await contasService.getContas();

      commit("set_contas", contas);
    },
    async fetch_balanco({ commit, dispatch }, periodo) {
      let balanco = await transacoesService.getBalanco(periodo);

      commit("set_balanco", balanco);
      dispatch("fetch_transacoes", { mes: periodo.mes, ano: periodo.ano });
    },
    async fetch_transacoes({ commit }, periodo) {
      let transacoes = await transacoesService.getTransacoes(periodo);
      commit("set_transacoes", transacoes);
    },
    async fetch_periodos({ commit }) {
      let periodos = await transacoesService.getPeriodos();
      commit("set_periodos", periodos);
    },
    async inserir_transacoes(
      {  dispatch, state },
      { de, para, amount, date: _data }
    ) {
      let date = new Date(_data);
      await transacoesService.inserirTransacoes({
        _debit_account: para,
        _credit_account: de,
        amount,
        date,
      });
      // commit("append_transacoes", transacao);
      const periodo = state._periodos[state._periodos.length - 1];
      if (periodo.mes != (date.getUTCMonth()+1)) {
        dispatch("fetch_periodos");
        periodo.mes++;
      }
      dispatch("fetch_balanco", periodo);
    },
  },
  mutations: {
    set_contas(state, contas = []) {
      state._contas.length = 0;
      state._contas.push(...contas);
      state._conta_map.clear();
      contas.forEach((v) => {
        state._conta_map.set(v._id, v);
      });
    },
    set_transacoes(state, transacoes) {
      state._transacoes.length = 0;
      state._transacoes.push(...transacoes);
    },
    set_balanco(state, balanco) {
      state._balanco.length = 0;
      state._balanco.push(...balanco);
    },
    set_periodos(state, periodos) {
      state._periodos.length = 0;
      state._periodos.push(...periodos);
    },
    append_transacoes(state, transacao) {
      if (!transacao) return;
      state._transacoes.push(transacao);
    },
  },
};
